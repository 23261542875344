import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import clsx from 'clsx'
import Link from 'next/link'
import { EnglishIcon, TurkishIcon } from '../data/svgIcons/all-svg-icons'
import { MaterialSymbolsTranslateRounded } from '../data/svgIcons/all-svg-icons'

export default function LanguageSelection() {
  return (
    <Menu as='div' className='relative inline-block text-left '>
      <div>
        <Menu.Button className='inline-flex w-full justify-center rounded-md  px-2 py-2 text-sm font-medium text-gray-100 shadow-sm'>
          <MaterialSymbolsTranslateRounded
            width={20}
            height={20}
            className='ml-5 text-neutral-200 duration-300 transition-all hover:text-neutral-400'
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='absolute right-0 z-50 mt-2 divide-y divide-neutral-700 w-36 origin-top-right rounded-md  shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
          <div className='flex flex-row items-center bg-neutral-900 divide-x divide-neutral-500/30 justify-center border border-neutral-500/50 rounded-md -mx-2'>
            <Menu.Item>
              {({ active }) => (
                <Link
                  href='/'
                  locale='tr'
                  className={clsx(
                    active
                      ? 'bg-neutral-800 text-neutral-100'
                      : 'text-neutral-100',
                    'flex items-center rounded-l-md px-4 py-3 text-sm',
                  )}
                >
                  <TurkishIcon />
                  <span className='ml-2'>TR</span>
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                  href={'/en'}
                  locale='en'
                  className={clsx(
                    active
                      ? 'bg-neutral-800 text-neutral-100'
                      : 'text-neutral-100',
                    ' px-4 py-3 rounded-r-md text-sm flex items-center',
                  )}
                >
                  <EnglishIcon />
                  <span className='ml-2'>EN</span>
                </Link>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
