import Link from 'next/link'
import Image from 'next/image'
import DropDownList from './dropDown'
import { LanguageContext } from '../hook/context'
import { useContext } from 'react'
import LanguageSelection from './languageSelect'

const Navbar = () => {
  const { t } = useContext(LanguageContext)
  return (
    <div className=''>
      <nav className='flex top-0 fixed w-full z-50 h-16 items-center'>
        <ul className='flex after:absolute after:h-[1px]  after:bottom-0  after:left-5 after:right-5 after:bg-white/10  w-full h-full px-5 sm:py-5 sm:pt-4 sm:pb-2 backdrop-blur bg-primaryBlack/70 shadow-md mx-auto max-w-[1400px] flex-row items-center justify-center text-white '>
          <li className='mr-auto '>
            <Link href='/'>
              <Image
                src='/logos/cx-w-logo.svg'
                alt='logo'
                width={188}
                height={25}
              />
            </Link>
          </li>
          <DropDownList />
          <ul className='hidden sm:inline-flex sm:justify-center sm:items-center'>
            {t.navbar.map((item: any, index: number) => (
              <li
                key={index}
                className='pl-4 leading-6 font-semibold text-neutral-200 duration-300 transition-all hover:text-neutral-400'
              >
                <Link href={item.link}>{item.name}</Link>
              </li>
            ))}
            <LanguageSelection />
          </ul>
        </ul>
      </nav>
    </div>
  )
}

export default Navbar
