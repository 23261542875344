import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import clsx from 'clsx'
import Link from 'next/link'
import {
  EnglishIcon,
  TurkishIcon,
  HamburgerMenu,
} from '../data/svgIcons/all-svg-icons'
import { LanguageContext } from '../hook/context'
import { useContext } from 'react'

export default function DropDown() {
  const { t } = useContext(LanguageContext)
  return (
    <Menu
      as='div'
      refName='change language'
      className='relative inline-block text-left sm:hidden '
    >
      <div>
        <Menu.Button className='inline-flex w-full justify-end rounded-md  pl-2 py-2 text-sm font-medium text-gray-100 shadow-sm'>
          <HamburgerMenu />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='absolute right-0 z-[100] mt-2 divide-y divide-neutral-700 w-36 origin-top-right rounded-md bg-neutral-900 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
          <div className='pt-2'>
            {t.navbar.map((item: any, index: number) => (
              <div key={index} className='py-1'>
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      href={item.link}
                      className={clsx(
                        active
                          ? 'bg-neutral-800 text-neutral-100'
                          : 'text-gray-100',
                        'block px-4 py-1 text-sm',
                      )}
                    >
                      {item.name}
                    </Link>
                  )}
                </Menu.Item>
              </div>
            ))}
          </div>
          <div className=''>
            <Menu.Item>
              {({ active }) => (
                <Link
                  href='/'
                  locale='tr'
                  about='change language to turkish'
                  className={clsx(
                    active
                      ? 'bg-neutral-800 text-neutral-100'
                      : 'text-neutral-100',
                    'flex items-center px-4 py-2 text-sm',
                  )}
                >
                  <TurkishIcon />
                  <span className='ml-2'>TR</span>
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                  href={'/en'}
                  locale='en'
                  about='change language to english'
                  className={clsx(
                    active
                      ? 'bg-neutral-800 text-neutral-100'
                      : 'text-neutral-100',
                    ' px-4 py-1 pb-3 text-sm flex items-center',
                  )}
                >
                  <EnglishIcon />
                  <span className='ml-2'>EN</span>
                </Link>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
